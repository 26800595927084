import GLightbox from "glightbox";
import no from "vanillajs-datepicker/js/i18n/locales/no";

class Gallery {
    constructor(element){
        this.element = element;

        let width = window.innerWidth;

        this.type = 'all';
        this.page = 1;
        if(width < 700){
            this.perPage = 1;
        } else if (width < 1000){
            this.perPage = 4;
        } else {
            this.perPage = 6;
        }


        this.nextPage = this.element.querySelector('[data-element="next"]');
        this.prevPage = this.element.querySelector('[data-element="prev"]');
        this.buttons = this.element.querySelectorAll('[data-element="button"]');
        this.applicationContainer = this.element.querySelector('[data-element="container"]');

        this._nextPageEvent = this._nextPageEvent.bind(this);
        this._prevPageEvent = this._prevPageEvent.bind(this);
        this._changeTypeEvent = this._changeTypeEvent.bind(this);

        this.nextPage.addEventListener('click', this._nextPageEvent);
        this.prevPage.addEventListener('click', this._prevPageEvent);

        this.buttons.forEach(button => {
            button.addEventListener('click', this._changeTypeEvent);
        });

        this._loadApplications();


    }

    _changeTypeEvent(event) {
        event.preventDefault();
        this.type = event.target.getAttribute('data-type');
        this.page = 1;
        this.lastPage = false;
        this.buttons.forEach(button => {
           let bt = button.getAttribute('data-type');
           if(bt == this.type) button.classList.add('active');
           else button.classList.remove('active')
        });
        this._loadApplications();
    }

    _nextPageEvent(event){
        if(!this.lastPage) {
            this.page++;
            this._loadApplications();
        } else {
            event.target.classList.add('dis-active');
        }
    }

    _prevPageEvent(event){
        if(this.page > 1) {
            this.page--;
            this.lastPage = false;
            this._loadApplications();
        }
    }

    _getFromApi(){
        let query = this._buildQuery();
        let url = '/wp-json/wp/v2/application/?'+query;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if(!response.ok){
                return response.json().then(function(response){
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        })
    }

    _buildQuery(){
        let query = [
            'per_page='+this.perPage,
            'page='+this.page,
            '_embed'
        ];
        if(this.type !== 'all'){
            query.push('type='+this.type);
        }
        return query.join('&');
    }

    _loadApplications(){
        this.isLoading = true;
        this._getFromApi().then(rensponse => {
            if(rensponse.length > 0){
                console.log(rensponse);
                this._generateApplications(rensponse);
            } else {
                if(this.page == 1) {
                    this._showNoImages();
                }
            }
        }).catch(error => {
            //Błąd
        });
    }

    _showNoImages() {
        this.applicationContainer.innerHTML = '';
        console.log('tu');
        let noIm = document.createElement('div');
        noIm.classList.add('no-images');
        noIm.innerHTML = "Brak zdjęć!<br/>Czekamy na Twoje zgłoszenie!";

        console.log(noIm);

        this.applicationContainer.append(noIm);
    }

    _generateApplications(applications){
        this.applicationContainer.innerHTML = '';
        applications.forEach(application => {
            let applicationElement = this._generateApplication(application);
            this.applicationContainer.append(applicationElement);
            let lightbox = new GLightbox();
        });
        if(applications.length < this.perPage){
            this.lastPage = true;
        }
        this.isLoading = false;
    }

    _generateApplication(application){
        let element = document.createElement('div');
        element.classList.add('slide__photo');

        let lightbox = document.createElement('a');
        lightbox.classList.add('glightbox');
        lightbox.setAttribute('data-gallery', application.id);
        lightbox.setAttribute('href', application._embedded["wp:featuredmedia"][0].source_url);

        let name = document.createElement('p');
        name.classList.add('slide__name');
        name.innerText = application.meta._first_name;

        let slideImage = document.createElement('img');
        slideImage.classList.add('slide__image');
        slideImage.setAttribute('src', application._embedded["wp:featuredmedia"][0].source_url);
        slideImage.setAttribute('alt', application.meta._first_name);

        let slideContainer = document.createElement('div');
        slideContainer.classList.add('slide__image-container');

        console.log(application.meta._award.length);

        if(application.meta._award.length > 0) {
            let mask = document.createElement('div');
            mask.classList.add('slide__image-mask');
            application.meta._award.forEach(award => {
                let icon = document.createElement('img');
                icon.classList.add('slide__image-icon');
                icon.setAttribute('src', "/wp-content/themes/meridol/dist/images/icon-" + award + ".png");
                slideContainer.appendChild(mask);
                slideContainer.appendChild(icon);
            });

        }

        slideContainer.appendChild(slideImage);
        lightbox.appendChild(slideContainer);

        element.appendChild(lightbox);
        element.appendChild(name);
        return element;
    }
}

export default Gallery;
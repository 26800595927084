import { Form } from '@netivo/base-scripts';
import GLightbox from 'glightbox';
import { Datepicker } from 'vanillajs-datepicker';
import Gallery from "./gallery";
import Pristine from "pristinejs/src/pristine";

let lightbox = new GLightbox();

function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

// window.initMap = initMap;

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

const elem = document.querySelector('input[name="date_purchase"]');
if(elem){
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let todayDate = dd  + '/' + mm + '/' + yyyy;
    const datepicker = new Datepicker(elem, {
        minDate: '28/06/2021',
        maxDate: todayDate,
        format: 'dd/mm/yyyy',
        autohide: true
    });
}

// let header = document.querySelector('.js-header');
// let sticky = header.offsetTop;
//
// window.onscroll = function(){stickyStart()};
//
// function stickyStart() {
//     if(window.pageYOffset > sticky){
//         header.classList.add('header--scrolled');
//     } else {
//         header.classList.remove('header--scrolled');
//     }
// }

window.addEventListener('DOMContentLoaded', () => {

    let inputFile = document.getElementById( 'file-input' );
    let infoArea = document.getElementById( 'file-upload-filename' );
    let labelFile = document.getElementById( 'file-input-label' );

    if(inputFile){
        inputFile.addEventListener( 'change', showFileName );
    }

    File.prototype.convertToBase64 = function(callback){
        let reader = new FileReader();
        reader.onloadend = function (e) {
            callback(e.target.result, e.target.error);
        };
        reader.readAsDataURL(this);
    };

    function showFileName( event ) {
        let input = event.srcElement;
        let fileName = input.files[0].name;
        infoArea.textContent = fileName;
        labelFile.textContent = 'Zmień zdjęcie';
    }


    let allCheckboxsButton = document.getElementById('allCheckboxsButton');
    let checkboxs = document.querySelectorAll('.form__input-checkbox');

    if(allCheckboxsButton){
        allCheckboxsButton.addEventListener('click', function(){
            if(allCheckboxsButton.checked){
                checkboxs.forEach(checkbox => {
                    checkbox.checked = true;
                })
            } else {
                checkboxs.forEach(checkbox => {
                    checkbox.checked = false;
                })
            }
        });
    }

    let menu_carousels = document.querySelectorAll('.js-menu-carousel');
    if (menu_carousels !== null) {
        menu_carousels.forEach(carousel => {
            if(carousel !== null) {
                if(carousel.classList.contains('slider--shown'))
                {
                    let m_crls = null;
                    let prev_button = carousel.querySelector('[data-element="button-prev"]');
                    let next_button = carousel.querySelector('[data-element="button-next"]');
                    m_crls = new Glide(carousel, {
                        perView: 1,
                        gap: '0',
                        type: 'carousel',
                    }).mount();

                    prev_button.addEventListener('click', (e) => {
                        e.preventDefault();

                        m_crls.go('<');
                    });
                    next_button.addEventListener('click', (e) => {
                        e.preventDefault();

                        m_crls.go('>');
                    });
                    carousel.setAttribute('data-run', 1);
                }
            }
        })
    }


    let e_crls = null;
    let events_carousel = document.querySelector('.js-events-carousel');
    if (events_carousel !== null) {
        let prev_button = events_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = events_carousel.parentNode.querySelector('[data-element="button-next"]');

        e_crls = new Glide(events_carousel, {
            perView: 1,
            gap: '0',
            type: 'carousel',
            bound: true,
            breakpoints: {
                991: {
                    perView: 1,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('>');
        });
    }

    let application_form = document.querySelector('.js-application-form');
    if (application_form !== null) {
        var pristine = new Pristine(application_form, {
            classTo: 'form__group',
            errorTextParent: 'form__group',
        });
        let recaptcha_key = application_form.getAttribute('data-recaptcha');
        new Form(application_form, {
            action: '/wp-json/meridol/v1/new_application',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        receipt_number: '',
                        date_purchase: '',
                        marketing_information: '',
                        commercial_information: '',
                        file: '',
                        file_name: '',
                        nonce: '',
                        recaptcha: '',
                    };

                    let form_first_name = el.querySelector('[name="first_name"]'),
                        form_last_name = el.querySelector('[name="last_name"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_phone = el.querySelector('[name="phone"]'),
                        form_receipt_number = el.querySelector('[name="receipt_number"]'),
                        form_date_purchase = el.querySelector('[name="date_purchase"]'),
                        form_marketing_info = el.querySelector('[name="marketing_information"]'),
                        form_commercial_info = el.querySelector('[name="commercial_information"]'),
                        form_file = el.querySelector('[name="file"]'),
                        form_nonce = el.querySelector('[name="nonce"]');

                    if (form_first_name !== null) data.first_name = form_first_name.value;
                    if (form_last_name !== null) data.last_name = form_last_name.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_phone !== null) data.phone = form_phone.value;
                    if (form_receipt_number !== null) data.receipt_number = form_receipt_number.value;
                    if (form_date_purchase !== null) data.date_purchase = form_date_purchase.value;
                    if (form_marketing_info !== null) data.marketing_information = form_marketing_info.value;
                    if (form_commercial_info !== null) data.commercial_information = form_commercial_info.value;
                    if (form_nonce !== null) data.nonce = form_nonce.value;
                    if (form_file !== null && form_file.value !== '') {
                        let f = el.querySelector('[name="file"]').files[0];
                        // console.log(f);
                        f.convertToBase64(base64 => {
                            data.file = encodeURIComponent(base64);
                            data.file_name = f.name;

                            grecaptcha.ready(function() {
                                grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                                    data.recaptcha = token;
                                    resolve(data);
                                });
                            });
                        });
                    } else {
                        grecaptcha.ready(function() {
                            grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                                data.recaptcha = token;
                                resolve(data);
                            });
                        });
                    }
                });
            },
            beforeSubmit: (el, hashed) => {
                let is_valid = pristine.validate();
                if(is_valid){
                    document.querySelector('.lds-ring').style.display = 'block';
                } else {
                    let top = el.querySelector('.has-danger').getBoundingClientRect().top + window.pageYOffset;
                    console.log(top);
                    window.scrollTo({top: top - 100, behavior: "smooth"});
                }
                console.log(is_valid);
                return is_valid;
            },
            success: (el, response) => {
                let formSection = document.getElementById('form-section');
                let formSuccess = document.getElementById('form-success');
                formSection.style.display = 'none';
                formSuccess.style.display = 'block';
                window.scrollTo(0, 0);

                if(window.gtag !== undefined){
                    window.gtag('event', 'application');
                }

            },
            error: (el, response) => {
                let formSection = document.getElementById('form-section');
                let formFailed = document.getElementById('form-failed');
                let failedText = document.getElementById('failedText');
                formSection.style.display = 'none';
                formFailed.style.display = 'block';
                failedText.innerText = response.toString().replace('Error: ', '');
                window.scrollTo(0, 0);
            }
        });
    }

    let apps = document.querySelector('.js-application');
    if(apps !== null) {
        new Gallery(apps);
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

